import { Aboyeur } from "@ifood/aboyeur";
import { getMerchantIdFromUrl } from "@whitelabel-webapp/shared/url-utils";

const accountRevision = 1;

export const accountAboyeur = new Aboyeur("account", {
  catch: {
    /**
     * @when An unexpected error occurs during an authentication
     */
    onError: (error: Error) => ({
      label: "error",
      metadata: { error: error.message, merchant_id: getMerchantIdFromUrl() },
    }),
  },
  header: {
    /**
     * @when The user click at logo
     */
    logo: () => ({
      label: "logo",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user search for catalog items
     */
    searchForCatalogItem: (query: string) => ({
      label: "search_catalog_item",
      metadata: { value: query, merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click to login
     */
    login: () => ({
      label: "login",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click to search for new addresses
     */
    searchForAddress: () => ({
      label: "search_address",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  headerSuspendedMenu: {
    /**
     * @when The user click to open suspended menu
     */
    open: () => ({
      label: "open",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click to close suspended menu
     */
    close: () => ({
      label: "close",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click to open orders page
     */
    orders: () => ({
      label: "orders",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click to open addresses modal
     */
    addresses: () => ({
      label: "address",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click to logout
     */
    logout: () => ({
      label: "logout",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  menu: {
    /**
     * @when The user click at catalog option
     */
    catalog: () => ({
      label: "catalog",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click at orders option
     */
    orders: () => ({
      label: "orders",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click at profile option
     */
    profile: () => ({
      label: "profile",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
  profile: {
    /**
     * @when The user open profile drawer
     */
    open: () => ({
      label: "open",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user close profile drawer
     */
    close: () => ({
      label: "close",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user is not logged
     */
    emptyState: () => ({
      label: "empty_state",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user is not logged and decided to realize login
     */
    login: () => ({
      label: "login",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click at address option
     */
    addresses: () => ({
      label: "address",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click at orders option
     */
    orders: () => ({
      label: "orders",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),

    /**
     * @when The user click at logout option
     */
    logout: () => ({
      label: "logout",
      metadata: { merchant_id: getMerchantIdFromUrl() },
    }),
  },
}).withMetadata({
  revision: accountRevision,
});
