import {
  checkoutAboyeur,
  checkoutAboyeur2,
} from "@whitelabel-webapp/checkout/shared/config";
import {
  CardTokensResponse,
  Customer,
} from "@whitelabel-webapp/checkout/shared/models";
import { snackbar } from "@whitelabel-webapp/shared/design-system";
import { useEffect, useState } from "react";

type useCardTokensResponse = {
  cardTokens: CardTokensResponse | undefined;
  isLoading: boolean;
};

export function useCardTokens(): useCardTokensResponse {
  const [cardTokens, setCardTokens] = useState<CardTokensResponse>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCardTokens = async () => {
      try {
        const cardTokensResponse = await Customer.getCardTokens();

        setCardTokens(cardTokensResponse);
      } catch (error: any) {
        setCardTokens([]);

        snackbar({
          message:
            "Não foi possível carregar os cartões. Cadastre um para continuar.",
          variant: "error",
        });
        checkoutAboyeur2.events.catch.onError(error);
        checkoutAboyeur.events.catch.onError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCardTokens();
  }, []);

  return { cardTokens, isLoading };
}
