import {
  checkoutAboyeur,
  checkoutAboyeur2,
} from "@whitelabel-webapp/checkout/shared/config";
import { Button, Flex, Text } from "@whitelabel-webapp/shared/design-system";
import { useMemo } from "react";

import { useCheckout } from "@app/domains/checkout";

import * as S from "./styles";

export const BagBar: React.VFC = () => {
  const { order, setCheckoutDetailsStatus } = useCheckout();

  const totalQuantity = useMemo(() => {
    if (!order.itemsList.length) {
      return 0;
    }

    return order.itemsList.reduce((acc, item) => acc + item.quantity, 0);
  }, [order.itemsList]);

  function handleClick() {
    if (!totalQuantity) return;
    checkoutAboyeur2.events.bag.click();
    checkoutAboyeur.events.bag.click();
    setCheckoutDetailsStatus("OPEN");
  }

  if (!totalQuantity) return null;

  return (
    <S.Wrapper
      onClick={handleClick}
      isEmpty={!totalQuantity}
      data-testid="bag-button"
    >
      <S.Container>
        <Text fontSize="12px" lineHeight="12px" textAlign="left">
          Total sem entrega
        </Text>
        <Flex gap="4px">
          <Text fontSize="16px" fontWeight="500">
            {order.totalItems.format()}
          </Text>
          <Text fontSize="12px" fontWeight="400" color="grayDarker">
            / {totalQuantity} itens
          </Text>
        </Flex>
      </S.Container>

      <Button py="8px" width="165px">
        <Text color="white" fontSize="tsm" fontWeight="500">
          Ver sacola
        </Text>
      </Button>
    </S.Wrapper>
  );
};
