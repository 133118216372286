import { yupResolver } from "@hookform/resolvers/yup";
import {
  checkoutAboyeur,
  checkoutAboyeur2,
} from "@whitelabel-webapp/checkout/shared/config";
import { useMerchant } from "@whitelabel-webapp/merchant/shared/merchant-store";
import { Dialog, Input, Text } from "@whitelabel-webapp/shared/design-system";
import React, { Component, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import InputMask, { InputState } from "react-input-mask";
import * as yup from "yup";

import { useCheckout } from "../../../../../../context";
import { LABELS } from "./constants";
import * as S from "./styles";

const validationSchema = yup.object().shape({
  document: yup.string().test({
    message: LABELS.INVALID_DOCUMENT_MESSAGE,
    test: (value) => {
      if (
        value === undefined ||
        value?.toString().replace(" ", "").length === 0 ||
        value?.toString().replace(" ", "").length === 14 ||
        value?.toString().replace(" ", "").length === 18
      ) {
        return true;
      }
      return false;
    },
  }),
});

const documentMask = (value?: string) => {
  if (value && value.length > 14) {
    return "99.999.999/9999-99";
  }
  return "999.999.999-999";
};

type InputMaskProps = Component<typeof InputMask>;

export const DocumentSelect = React.forwardRef<HTMLDivElement>((_, ref) => {
  const {
    merchant: { isDocumentRequired },
  } = useMerchant();
  const {
    order: { document = "" },
    setDocument,
  } = useCheckout();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const documentSelectForm = useForm({
    defaultValues: {
      document,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (values) => {
    setDocument(values.document);
    setIsDialogOpen(false);
  };

  function handleOpenDialog() {
    checkoutAboyeur2.events.document.click();
    checkoutAboyeur.events.document.click();
    setIsDialogOpen(true);
  }

  function handleCloseDialog() {
    documentSelectForm.reset();
    setIsDialogOpen(false);
  }

  function beforeMaskedValueChange(newState: InputState, oldState: InputState) {
    const { value: newValue } = newState;
    const { value: oldValue } = oldState;

    if (newValue !== oldValue) {
      documentSelectForm.setValue("document", newValue);
    }

    return {
      ...newState,
    };
  }

  const { isDirty, isSubmitted, isValid } = useFormState({
    control: documentSelectForm.control,
  });
  const shouldDisableSubmitButton = !isDirty || (isSubmitted && !isValid);

  return (
    <div ref={ref}>
      <S.Wrapper justifyContent="space-between">
        <S.Container flexDirection="column">
          <S.Heading variant="small" fontWeight="400" m="0" my="auto">
            {`${LABELS.DOCUMENT_HEADING_TEXT}${isDocumentRequired ? "*" : ""}`}
          </S.Heading>
          {document ? (
            <Text variant="medium" color="grayDarker">
              {document}
            </Text>
          ) : null}
        </S.Container>
        <S.Container centered>
          <S.Button
            variant="tertiary"
            onClick={handleOpenDialog}
            aria-label={
              document
                ? LABELS.SWITCH_DOCUMENT_DIALOG_BUTTON_LABEL
                : LABELS.ADD_DOCUMENT_DIALOG_BUTTON_LABEL
            }
          >
            <Text fontWeight="400" variant="medium" color="primary">
              {document
                ? LABELS.SWITCH_DOCUMENT_DIALOG_BUTTON_TEXT
                : LABELS.ADD_DOCUMENT_DIALOG_BUTTON_TEXT}
            </Text>
          </S.Button>
        </S.Container>
      </S.Wrapper>
      <Dialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        data-testid="document-dialog"
      >
        <S.DialogHeader>
          <S.Heading variant="small" fontWeight="400" m="0">
            {`${LABELS.DOCUMENT_HEADING_TEXT}${isDocumentRequired ? "*" : ""}`}
          </S.Heading>
          <S.Button variant="tertiary" onClick={handleCloseDialog}>
            {LABELS.CLOSE_DOCUMENT_DIALOG_BUTTON_TEXT}
          </S.Button>
        </S.DialogHeader>
        <form onSubmit={documentSelectForm.handleSubmit(onSubmit)}>
          <Dialog.Body>
            <Controller
              name="document"
              render={({ field: { onChange, value } }) => (
                <InputMask
                  mask={documentMask(value)}
                  maskChar={null}
                  value={value}
                  onChange={onChange}
                  beforeMaskedValueChange={beforeMaskedValueChange}
                >
                  {(props: InputMaskProps) => (
                    <Input
                      {...props}
                      type="tel"
                      name="document"
                      error={
                        documentSelectForm.formState.errors.document?.message ||
                        ""
                      }
                    />
                  )}
                </InputMask>
              )}
              control={documentSelectForm.control}
            />
          </Dialog.Body>
          <Dialog.Footer>
            <S.DialogButton type="submit" disabled={shouldDisableSubmitButton}>
              {LABELS.SUBMIT_DOCUMENT_BUTTON_TEXT}
            </S.DialogButton>
          </Dialog.Footer>
        </form>
      </Dialog>
    </div>
  );
});
